// =============================
// Imports
// =============================

// External dependencies
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import _get from 'lodash/get';

// Config
import { withTranslation } from '../config/i18n';

// Actions
import storeWrapper from '../store';
import {
  getTracksSearchData as getTracksSearchDataBase,
  resetTracksSearchData as resetTracksSearchDataBase,
  continueMaiaSearch as continueMaiaSearchBase,
} from '../store/actions/SearchActions';

// Components
import PrivateRoute from '../components/containers/privateRoute';
import Search from '../components/layouts/search';

// Helpers
import { transformQueryToSearch } from '../helpers/search';
import { getWebsiteTitle } from '../helpers/misc';
import * as pth from '../helpers/proptypes';
import * as LastRoute from '../helpers/lastroute';

// =============================
// Page
// =============================

class SearchPage extends PureComponent {
  static propTypes = {
    config: pth.config.isRequired,
    transformedQuery: pth.searchquery.isRequired,
    results: PropTypes.arrayOf(pth.track).isRequired,
    total: PropTypes.shape({
      value: PropTypes.number,
      relation: PropTypes.oneOf(['gte', 'eq']),
    }).isRequired,
    hasMoreResults: PropTypes.bool.isRequired,
    /** If True, a Maia search is ongoing. */
    isDoingMaiaSearch: PropTypes.bool.isRequired,
    getTracksSearchData: PropTypes.func.isRequired,
    resetTracksSearchData: PropTypes.func.isRequired,
    continueMaiaSearch: PropTypes.func.isRequired,
    router: PropTypes.shape({
      asPath: PropTypes.string,
      query: PropTypes.object, // eslint-disable-line react/forbid-prop-types
      events: PropTypes.shape({
        on: PropTypes.func,
        off: PropTypes.func,
      }),
    }).isRequired,
    t: PropTypes.func.isRequired,
  };

  static getInitialProps = storeWrapper.getInitialPageProps(store => async ({ query }) => {
    const transformedQuery = transformQueryToSearch(query);
    await store.dispatch(getTracksSearchDataBase(transformedQuery, { reload: true }));

    return {
      transformedQuery,
      namespacesRequired: ['common', 'pages', 'components', 'errors'],
    };
  });

  componentDidMount() {
    const { transformedQuery, continueMaiaSearch } = this.props;

    // NOTE: We can use transformedQuery from initial props on mounting
    continueMaiaSearch(transformedQuery);
  }

  componentDidUpdate(prevProps) {
    const {
      isDoingMaiaSearch,
      continueMaiaSearch,
      router: { query, asPath },
    } = this.props;

    // NOTE: We wait until the path has changed to send the correct query when doing maia search
    if (isDoingMaiaSearch && prevProps.router.asPath !== asPath) {
      continueMaiaSearch(transformQueryToSearch(query));
    }

    LastRoute.capture(window.location.protocol);
  }

  componentWillUnmount() {
    const { resetTracksSearchData } = this.props;

    resetTracksSearchData();
  }

  getMoreResults = () => {
    const {
      router: { query },
      getTracksSearchData,
    } = this.props;

    getTracksSearchData(transformQueryToSearch(query));
  };

  render() {
    const { config, results, total, hasMoreResults, isDoingMaiaSearch, t } = this.props;

    return (
      <PrivateRoute>
        <NextSeo title={getWebsiteTitle(config, t('pages:search.meta_title'))} noindex />
        <Search
          results={results}
          total={total}
          hasMoreResults={hasMoreResults}
          isDoingMaiaSearch={isDoingMaiaSearch}
          getResults={this.getMoreResults}
          t={t}
        />
      </PrivateRoute>
    );
  }
}

function mapStateToProps({ search }) {
  return {
    results: search.tracksSearch.data.results,
    total: search.tracksSearch.data.total,
    hasMoreResults:
      _get(search, 'tracksSearch.data.total.value', 0)
        - _get(search, 'tracksSearch.data.results', []).length
      > 0,
    isDoingMaiaSearch: search.tracksSearch.isDoingMaiaSearch,
  };
}

export default compose(
  withRouter,
  withTranslation('pages'),
  connect(mapStateToProps, {
    getTracksSearchData: getTracksSearchDataBase,
    resetTracksSearchData: resetTracksSearchDataBase,
    continueMaiaSearch: continueMaiaSearchBase,
  }),
)(SearchPage);
