// =============================
// Imports
// =============================

import styled, { css, keyframes } from 'styled-components';

import Button from '../../../presentationals/button';

// =============================
// Styles
// =============================

export const Loading = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  flex-direction: column;
  height: calc(100vh - 12rem);
  justify-content: center;
`;

export const StyledDescription = styled.div`
  line-height: 1.4;
  max-width: 60rem;
  padding: 0 5rem;
  text-align: center;
`;

const side = '8rem';
const l = '6rem';

const plane = (w, h) => css`
  height: ${h};
  transform-style: preserve-3d;
  width: ${w};
`;

const center = () => css`
  bottom: 0;
  left: 0;
  margin: auto;
  position: relative;
  right: 0;
  top: 0;
`;

const rotate = keyframes`
0% {
  transform: rotateX(0) rotateY(0) rotateZ(0);
}
100% {
  transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
}
`;

export const LoadingContainer = styled.div`
  align-items: center;
  height: 8rem;
  margin-bottom: 5rem;
  margin-top: 5rem;
  position: relative;
`;

export const Plane = styled.div`
  ${center};
  animation: ${rotate} 20s infinite linear;
  transform: rotateX(60deg) rotateZ(-30deg);
  ${plane(side, side)};
`;

const transform = index => css`
  &:nth-child(${index}) {
    transform: rotateZ(calc(${index} / 5 * 360deg)) rotateX(63.435deg);
  }
`;

export const Circle = styled.div`
  border-radius: 100%;
  box-shadow: 0 0 4rem ${({ theme }) => theme.colors.text},
    inset 0 0 4rem ${({ theme }) => theme.colors.text};
  box-sizing: border-box;
  height: ${side};
  position: absolute;
  transform-style: preserve-3d;
  width: ${side};

  &::before,
  &::after {
    content: '';
    display: block;
    ${center};
    width: 10%;
    height: 10%;
    border-radius: 100%;
    background: ${({ theme }) => theme.colors.text};
    box-sizing: border-box;
    box-shadow: 0 0 4rem 0.135rem ${({ theme }) => theme.colors.text};
  }

  &::before {
    transform: translateZ(-${l});
  }
  &::after {
    transform: translateZ(${l});
  }

  ${transform(1)};
  ${transform(2)};
  ${transform(3)};
  ${transform(4)};
  ${transform(5)};
`;

export const StyledButton = styled(Button).attrs({
  color: 'error',
  size: 'medium',
})``;
