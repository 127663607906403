// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { Div } from '@mewo/components';

import withResponsive from '../../../hoc/withResponsive';

import Filters from '../../../containers/filters';
import Link from '../../../other/link';
import MusicItem from '../../../containers/musicitem';
import PageWrapper from '../../../other/pagewrapper';

import * as playerCtx from '../../../../store/constants/PlayerContexts';

import * as pth from '../../../../helpers/proptypes';
import { transformTotalValueToString } from '../../../../helpers/search';

import { Name, StyledMusicItemOptionsToggler, Title, ContainerSearch, NoResults } from '../../entity.styles';
import {
  Loading,
  StyledDescription,
  Plane,
  Circle,
  LoadingContainer,
  StyledButton,
} from './styles';

// =============================
// Stories
// =============================

class Search extends Component {
  static displayName = 'SearchDesktop';

  static propTypes = {
    getResults: PropTypes.func.isRequired,
    hasMoreResults: PropTypes.bool.isRequired,
    /** If True, a Maia search is ongoing. */
    isDoingMaiaSearch: PropTypes.bool.isRequired,
    /** If True, user is on a mobile device. */
    isMobile: PropTypes.bool.isRequired,
    results: PropTypes.arrayOf(pth.track).isRequired,
    t: PropTypes.func.isRequired,
    total: PropTypes.shape({
      value: PropTypes.number,
      relation: PropTypes.oneOf(['gte', 'eq']),
    }).isRequired,
  };

  render() {
    const {
      getResults,
      hasMoreResults,
      isDoingMaiaSearch,
      isMobile,
      results,
      t,
      total,
    } = this.props;

    if (isDoingMaiaSearch) {
      return (
        <ContainerSearch>
          <Loading>
            <StyledDescription>{t('pages:search.doing_maia_search')}</StyledDescription>
            <LoadingContainer>
              <Plane>
                <Circle />
                <Circle />
                <Circle />
                <Circle />
                <Circle />
                <Circle />
              </Plane>
            </LoadingContainer>
            <Link route="/search">
              <StyledButton type="button">
                {t('pages:search.cancel_maia_search')}
              </StyledButton>
            </Link>
          </Loading>
        </ContainerSearch>
      );
    }

    return (
      <InfiniteScroll
        hasMore={hasMoreResults}
        initialLoad={!isMobile}
        loadMore={(...args) => {
          /* https://github.com/CassetteRocks/react-infinite-scroller/pull/193 */
          if (hasMoreResults) {
            getResults(...args);
          }
        }}
        threshold={250}
        useWindow={false}
      >
        <PageWrapper type="search">
          <Div mb="4.8rem">
            <Name>{t('pages:search.search_results')}</Name>
            <Filters />
          </Div>
          <Title>
            <div>
              {transformTotalValueToString(total)}
              &nbsp;
              {t('pages:entity.tracks')}
            </div>
            <StyledMusicItemOptionsToggler />
          </Title>

          {results.length === 0 && (
            <NoResults center>{t('pages:search.no_results')}</NoResults>
          )}

          {results.map(e => (
            <MusicItem key={e.id} music={e} contextName={playerCtx.SEARCH} />
          ))}
        </PageWrapper>
      </InfiniteScroll>
    );
  }
}

export default withResponsive(Search);
