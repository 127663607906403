// =============================
// Imports
// =============================

import { PureComponent } from 'react';

import Responsive from '../../other/responsive';

import SearchDesktop from './desktop';
import SearchMobile from './mobile';

// =============================
// Component
// =============================

class Search extends PureComponent {
  static displayName = 'SearchResponsive';

  render() {
    return (
      <Responsive
        DesktopComponent={SearchDesktop}
        MobileComponent={SearchMobile}
        props={this.props}
      />
    );
  }
}

export default Search;
