// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';

import withResponsive from '../../../hoc/withResponsive';

import Link from '../../../other/link';
import FilterBar from '../../../containers/filterbar';
import MusicItem from '../../../containers/musicitem';
import PageWrapper from '../../../other/pagewrapper';

import * as playerCtx from '../../../../store/constants/PlayerContexts';

import * as pth from '../../../../helpers/proptypes';
import { transformTotalValueToString } from '../../../../helpers/search';

import { NoResults, StyledMusicItemOptionsToggler, Title } from '../../entity.styles';
import {
  Loading,
  StyledDescription,
  Plane,
  Circle,
  LoadingContainer,
  StyledButton,
  MusicItemList,
  StyledFilters,
} from './styles';

// =============================
// Stories
// =============================

class Search extends Component {
  static displayName = 'SearchMobile';

  static propTypes = {
    getResults: PropTypes.func.isRequired,
    hasMoreResults: PropTypes.bool.isRequired,
    /** If True, a Maia search is ongoing. */
    isDoingMaiaSearch: PropTypes.bool.isRequired,
    /** If True, user is on a mobile device. */
    isMobile: PropTypes.bool.isRequired,
    results: PropTypes.arrayOf(pth.track).isRequired,
    t: PropTypes.func.isRequired,
    total: PropTypes.shape({
      value: PropTypes.number,
      relation: PropTypes.oneOf(['gte', 'eq']),
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      filterBarMinimized: true,
    };
  }

  handleFilterBarClose = () => this.setState({
    filterBarMinimized: true,
  });

  handleMinimizedOnClick = () => this.setState({
    filterBarMinimized: false,
  });

  render() {
    const {
      getResults,
      hasMoreResults,
      isDoingMaiaSearch,
      isMobile,
      results,
      t,
      total,
    } = this.props;
    const { filterBarMinimized } = this.state;

    if (isDoingMaiaSearch) {
      return (
        <PageWrapper isDoingMaiaSearch type="search">
          <Loading>
            <StyledDescription>{t('pages:search.doing_maia_search')}</StyledDescription>
            <LoadingContainer>
              <Plane>
                <Circle />
                <Circle />
                <Circle />
                <Circle />
                <Circle />
                <Circle />
              </Plane>
            </LoadingContainer>
            <Link route="/search">
              <StyledButton type="button">
                {t('pages:search.cancel_maia_search_mobile')}
              </StyledButton>
            </Link>
          </Loading>
        </PageWrapper>
      );
    }

    return (
      <InfiniteScroll
        hasMore={hasMoreResults}
        initialLoad={!isMobile}
        loadMore={(...args) => {
          /* https://github.com/CassetteRocks/react-infinite-scroller/pull/193 */
          if (hasMoreResults) {
            getResults(...args);
          }
        }}
        threshold={250}
        useWindow={false}
      >
        <PageWrapper type="search">
          <FilterBar
            minimized={filterBarMinimized}
            minimizedOnClick={this.handleMinimizedOnClick}
            onClose={this.handleFilterBarClose}
          />
          <StyledFilters />
          <Title withPadding>
            <div>
              {transformTotalValueToString(total)}
              &nbsp;
              {t('pages:entity.tracks')}
            </div>
            <StyledMusicItemOptionsToggler />
          </Title>
          <MusicItemList>
            {results.length === 0 && (
              <NoResults>{t('pages:search.no_results')}</NoResults>
            )}
            {results.map(e => (
              <MusicItem key={e.id} music={e} contextName={playerCtx.SEARCH} showSimilarity />
            ))}
          </MusicItemList>
        </PageWrapper>
      </InfiniteScroll>
    );
  }
}

export default withResponsive(Search);
